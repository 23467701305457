function App() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>🚀 My React App is Working! 🎉</h1>
      <p>If you see this message, the issue is fixed.</p>
    </div>
  );
}

export default App;
